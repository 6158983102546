import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/terms.md"

export default function TermsPage() {
  return (
    <LegalLayout
      description="All the terms that you agree to when you sign up for Ready Five."
      source={source}
      title="Terms of Service"
    />
  )
}
